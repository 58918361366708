@import "custom-styles/reset";
@import "custom-styles/variables";
@import "custom-styles/mixins";
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Raleway");
@import "custom-styles/fa-fonts";
@import "custom-styles/general-styles";
@import "custom-styles/navi";
@import "custom-styles/header";
@import "custom-styles/cta-top";
@import "custom-styles/footer";
@import "custom-styles/featured-box";
@import "custom-styles/posts-block";
@import "custom-styles/sidebar";
@import "custom-styles/types";
@import "custom-styles/forms";
@import "custom-styles/featured-page";
@import "custom-styles/who-answers.scss";

// TEMP STYLES
img {
	max-width: 100%;
}
section.page article p.wp-caption-text {
	text-align: center;
	font-size: 14px;
	width: 85%;
	margin: 5px auto 0;
}
.alignright {
	@media (min-width: 568px) {
		float: right;
		margin: 5px 0 10px 40px;
	}
}

.featured_img_wrap {
	max-width: 375px;
	width: 100%;
	margin: 0 auto 20px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	p {
		font-size: 14px;
		font-style: italic;
		width: 80%;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		float: right;
		margin: 5px 0 10px 40px;
	}
}

// for JSON testing
#sd-meta {
	margin-bottom: 40px;
}
#sd-meta small {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.5);
	display: block;
}

// clearfix stuff
.clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
}
.clear,
.clearfix {
	clear: both;
}
