/**
 * Carousel.sass
 * @author: Dang Van Thanh
 * @github: https://github.com/dangvanthanh/carousel.sass
 * @description: A Simple Carousel Pure CSS Using Sass
 * @version: 1.0.0
 */
%animation-default {
  opacity: 1 !important;
  z-index: 3;
}

@mixin carousel($items, $animation: 'default') {
  .carousel {
    width: 100%;
    position: relative;
    overflow: hidden;
    max-height: 215px;

    >input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      top: 0;

      &:checked {

        ~.carousel__items .carousel__item,
        ~.carousel__prev>label,
        ~.carousel__next>label {
          opacity: 0;
        }
      }

      @for $i from 1 through $items {
        &:nth-child(#{$i}) {
          &:checked {
            ~.carousel__items .carousel__item {
              @if $animation=='default' {
                &:nth-child(#{$i}) {
                  opacity: 1;
                }
              }
            }

            ~.carousel__prev {
              >label {
                @if $i==1 {
                  &:nth-child(#{$items}) {
                    @extend %animation-default;
                  }
                }

                @else if $i==$items {
                  &:nth-child(#{$items - 1}) {
                    @extend %animation-default;
                  }
                }

                @else {
                  &:nth-child(#{$i - 1}) {
                    @extend %animation-default;
                  }
                }
              }
            }

            ~.carousel__next {
              >label {
                @if $i==$items {
                  &:nth-child(1) {
                    @extend %animation-default;
                  }
                }

                @else {
                  &:nth-child(#{$i + 1}) {
                    @extend %animation-default;
                  }
                }
              }
            }

            ~.carousel__nav {
              >label {
                &:nth-child(#{$i}) {
                  background: #fff;
                  cursor: default;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }

    &__items {
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: 100%;
      height: 600px;
      position: relative;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: opacity 2s;
      -webkit-transition: opacity 2s;

      img {
        width: 100%;
        vertical-align: middle;
        height: 200px;
        object-fit: cover;
      }
    }

    &__prev,
    &__next {
      >label {
        border-radius: 50%;
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        opacity: 0;
        z-index: 2;

        &:hover,
        &:focus {
          opacity: .5 !important;
        }

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: inherit;
          height: inherit;
        }

        &:before {
          background: linear-gradient(to top, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%),
            linear-gradient(to left, #fff 0%, #fff 10%, rgba(51, 51, 51, 0) 10%);
          width: 60%;
          height: 60%;
          top: 20%;
        }
      }
    }

    &__prev {
      >label {
        left: 2%;

        &:before {
          left: 35%;
          top: 20%;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
    }

    &__next {
      >label {
        right: 2%;

        &:before {
          left: 10%;
          transform: rotate(315deg);
          -webkit-transform: rotate(315deg);
        }
      }
    }

    &__nav {
      position: absolute;
      bottom: 3%;
      left: 0;
      text-align: center;
      width: 100%;
      z-index: 3;

      >label {
        border: 1px solid #fff;
        display: inline-block;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 .125%;
        width: 15px;
        height: 15px;
      }
    }
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

@include carousel(5);

.hero.featured {
  .hero-lead.featured {
    width: 40%;
  }

  .focus {
    display: inline-block;
    clear: both;
    width: 100%;
  }

  ul {
    margin: 20px 0;

    li {
      padding-left: 0;
    }
  }

  ul.lists {
    margin: 50px 0 0;
    text-align: center;
    display: inline-block;
    width: 100%;

    >li {
      margin-bottom: 30px;
    }

    ul {
      text-align: center;
    }

    h3 {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 768px) {
    .carousel {
      max-height: 350px;

      img {
        height: 350px;
      }
    }

    ul.lists>li {
      width: 33.33333%;
      float: left;
      padding: 0 15px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .carousel {
      width: 60%;
      float: right;
      max-height: 300px;

      img {
        height: 300px;
      }

      ul {
        margin: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    .hero-lead.featured {
      width: 40%
    }
  }
}

.page-wrap.page.featured article {
  @media (min-width: 1200px) {
    width: 100%;
  }
}


// parent page
.featured-parent-page {
  .feat-box {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      height: 200px;
      width: 100%;
      max-width: 100%;

      .feat-img {
        width: 50%;
        float: left;
      }
    }

    @media (min-width: 992px) {
      .feat-img {
        width: 40%;
        margin-right: 30px;
      }
    }
  }

}