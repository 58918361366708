/* ========== WHO ANSWERS LIGHTBOX ========== */

#who_answers {
	display: none;
	width: 90%;
	max-width: 500px;
	border-radius: 0.25rem;
	background: #fff;
	padding: 20px 25px 1px;
	font-size: 16px;
}

#who_answers .close {
	position: absolute;
	top: 13px;
	right: 16px;
	font-size: 30px;
	line-height: 1;
	cursor: pointer;
}

#who_answers p {
	margin: 0 0 20px;
}

#who_answers ul {
	margin: 0 0 15px 20px;
}

.who_answers,
.who-answers {
	font-size: 11px;
	line-height: 1;
	cursor: pointer;
}

.who_answers i,
.who-answers i {
	color: #13a7dd;
	margin: 0 3px 0 0;
}

#header .who_answers {
	float: left;
	color: #fff;
	margin: 15px 0 0 15px;
	@media (min-width: 992px) {
		float: right;
		margin: 15px 20px 0 0;
	}
}

.hero .who_answers {
	margin: 0 0 0 10px;
	vertical-align: 3px;
}

.hero .who_answers i {
	padding: 0;
}

#footer .sticky .who_answers {
	max-width: 150px;
	margin: 0px auto 0;
	color: #fff;
	@media (min-width: 768px) {
		color: #000;
		margin: 5px auto 0;
	}
}

#sidebar .questions .who_answers {
	max-width: 150px;
	text-align: center;
	margin: auto;
}

#sidebar .questions .who_answers i {
	margin: 0 3px 0 0;
	padding: 0;
}

.hero-lead.help .who_answers {
	max-width: 150px;
	margin: 0 auto 20px;
}

.who_answers.shortcode {
	font-weight: 400;
	margin: 0 1px 0 4px;
	vertical-align: 2px;
}
