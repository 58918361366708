.feat-box {
	border: 1px solid silver;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	background: #fff;
	max-width: 355px;
	margin: 0 auto;

	.feat-img {
		height: 200px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.flag {
			padding: 5px 10px;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 14px;
			color: #fff;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.5);

			a {
				color: #fff;
			}
		}
	}

	.feat-text {
		padding: 15px;

		>* {
			margin-bottom: 10px;
		}

		h3 a {
			color: $headings-color;
		}
	}

	@media (min-width: 1200px) {
		max-width: 450px;
		float: right;
	}
}