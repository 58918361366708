@media (max-width: 991px) {
	.sidenav {
		height: 90vh;
		width: 0;
		position: fixed;
		z-index: 1;
		top: 0;
		right: 0;
		background-color: $footer-blue;
		overflow-x: hidden;
		transition: 0.5s;
		padding-top: 60px;
		padding-bottom: 60px;

		&.active {
			width: 250px;
		}

		a {
			padding: 8px 8px 8px 32px;
			text-decoration: none;
			font-size: 18px;
			color: #fff;
			display: block;
			transition: 0.3s;

			&:hover,
			&:focus,
			&:active {
				color: rgba(255, 255, 255, 0.7);
			}
		}

		ul i {
			display: none;
		}

		.heading {
			padding: 8px 8px 8px 32px;
			font-size: 18px;
			color: $theme-yellow;
			font-weight: 600;
			margin: 0;
		}

		ul ul a {
			font-weight: 300;
			font-size: 16px;
			padding: 0px 8px 0 32px;
		}

		.closebtn {
			position: absolute;
			top: 0;
			right: 25px;
			font-size: 36px;
			margin-left: 50px;
		}
	}
}

@media (min-width: 992px) {
	.menu-toggle,
	.closebtn {
		display: none;
	}

	#mySidenav {
		float: right;

		> ul > li {
			display: block;
			float: left;
			margin-left: 25px;
			position: relative;
			line-height: 50px;

			> a {
				display: block;
				line-height: 50px;
				color: #000;
			}

			> ul {
				display: none;
				position: absolute;
				width: 900px;
				top: 50px;
				right: -150px;
				z-index: 5000;
				background-color: #fff;
				padding: 20px 10px 30px 30px;
				list-style: none;
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

				.heading {
					margin-bottom: 10px;
					font-weight: 600;
				}

				a {
					display: block;
					line-height: 1.7;
				}
			}

			&:hover > ul,
			&:focus > ul,
			&:active > ul {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				column-gap: 16px;
			}
		}
	}
}
