.page-wrap.page.help article ul {
	list-style: none;
	margin: 0;
}
#field_1_8, #field_1_17 {
	clear:both;
}
form {
	ul.gform_fields {
		padding: 0;
		list-style: none;
		margin-bottom: 0;
	}
	.gfield_label,
	.ginput_complex label {
		display: none;
	}
	.gform_footer {
		padding: 15px 0 0;
	}
	input,
	select,
	textarea {
		position: relative;
		display: block;
		width: 264px;
		padding: 6px 12px;
		margin-bottom: 15px;
		font-size: 14px;
		line-height: 1.428571429;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		-webkit-transition: border-color ease-in-out .15s , box-shadow ease-in-out .15s;
		-webkit-transition: border-color ease-in-out .15s , -webkit-box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s , -webkit-box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s , box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s , box-shadow ease-in-out .15s , -webkit-box-shadow ease-in-out .15s;
	}
	select {
		/* reset */
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image:url(../images/select-arrow.png);
		background-position-x:97%;
		background-position-y: 12px;
		background-size:20px 12px;
		background-repeat: no-repeat;
		width: 290px;
	}
	textarea {
		height: auto;
	}
	.gform_button {
		background: $link-blue;
		color: #fff;
		margin: 0 auto;
	}
	.gform_validation_container { display: none; }
	.validation_error,
	.validation_message { 
		color: red; 
		font-size: 15px;
		float: left;
		padding-right: 5px;
	}
	@media (min-width: 768px) {
		input {
			width: 325px;
		}
		select {
			width: 350px;
		}
		textarea {
			width: 96%;
		}
		#field_1_11 {
			width: 100%;
			display: table;
			span {
				width: 48%;
				float: left;
			}
			.name_last {
				float: right;
			}
		}
		#field_1_3,
		#field_1_12 {
			width: 48%;
			float: left;
		}
		#field_1_4,
		#field_1_13 {
			width: 48%;
			float: right;
		}
		
	}
	@media (min-width: 992px) {
		max-width: 872px;
		margin: 0 auto;
		input,
		select {
			width: 390px;
		}
		textarea {
			width: 845px;
		}
	}
}

