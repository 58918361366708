@mixin split($columns, $gap) {	
	// Number of columns
	-webkit-column-count: $columns;
	-moz-column-count: $columns;
	-o-column-count: $columns;
	column-count: $columns;	
	// Gap size
	-webkit-column-gap: $gap;
	-moz-column-gap: $gap;
	-o-column-gap: $gap;
	column-gap: $gap;
}

