footer {
	background-color: $footer-blue;
	text-align: center;
	padding-bottom: 70px;

	.links {
		margin-bottom: 50px;

		a {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}

		h4,
		h4 a {
			color: $link-blue;
			margin-bottom: 20px;
			font-size: 21px;
			font-weight: 500;
		}

		ul {
			list-style: none;
		}

		@media (min-width: 992px) {
			width: 33%;
			float: left;
		}

		@media (min-width: 1200px) {
			width: 20%;
		}
	}

	.double.links {
		ul > li {
			padding-bottom: 5px;
		}
		@media (min-width: 992px) {
			width: 66%;
		}

		@media (min-width: 1200px) {
			width: 80%;

			ul {
				@include split(2, 50px);
			}
		}
	}

	.info {
		margin-top: 25px;
		padding-top: 25px;
		border-top: 1px solid #264158;
		display: inline-block;
		text-align: center;
		width: 100%;

		.footer-brand {
			color: #fff;
			font-weight: 600;
			line-height: 24px;
			font-size: 24px;
			text-decoration: underline;
			display: block;
			margin-bottom: 20px;
		}

		p {
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			color: #536e84;
			margin-bottom: 15px;
		}

		.disclaimer {
			display: block;
			width: 290px;
			height: 320px;
			margin: 0 auto 20px;
			background-image: url(../../public/images/disclaimer-2.png);
			background-repeat: no-repeat;
			background-position: -4px -8px;

			@media (min-width: 480px) {
				background-position: -368px -8px;
				width: 384px;
				height: 230px;
			}

			@media (min-width: 667px) {
				background-position: -802px -8px;
				width: 637px;
				height: 140px;
			}

			@media (min-width: 768px) {
				background-position: -774px -192px;
				width: 738px;
				height: 125px;
			}

			@media (min-width: 992px) {
				background-position: -4px -364px;
				width: 872px;
				height: 110px;
			}

			@media (min-width: 1200px) {
				background-position: -7px -505px;
				width: 1170px;
				height: 85px;
			}
		}
	}

	.sticky {
		width: 100%;
		height: 70px;
		position: fixed;
		z-index: 1000;
		bottom: 0;
		left: 0;
		background-color: $light-blue;
		line-height: 1.2;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

		@media (min-width: 768px) {
			background-color: #e9f1fe;
		}

		@media (min-width: 1200px) {
			padding: 15px 0 0;
		}

		.message {
			display: none;
			line-height: 1;

			@media (min-width: 768px) {
				background-color: #4888f0;
				text-align: center;
				padding: 7px 10px 10px;
				margin: 8px auto 0;
				display: table;

				.text {
					display: inline;
				}
			}

			@media (min-width: 1200px) {
				display: inline-block;
				padding: 9px 20px 11px;
				vertical-align: middle;
				margin: 0;
			}
		}

		.message a {
			color: $header-bg-color;
			font-size: 13px;
			font-weight: 600;

			@media (min-width: 768px) {
				color: #fff;
				font-size: 20px;
				font-weight: 400;
			}
		}

		.message-mobile {
			@media (min-width: 320px) {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px 10px 7px;
			}
			@media (min-width: 768px) {
				display: none;
			}
			.text a {
				color: $theme-yellow;
				font-size: 16px;
				padding: 0 15px 0 0;
				@media (max-width: 346px) {
					font-size: 14px;
				}
			}
		}

		.message .phone-link {
			font-size: 28px;
			font-weight: 600;
			display: block;

			@media (min-width: 768px) {
				font-weight: 700;
				color: #fff;
				display: inline;
				font-size: 20px;
			}
		}

		.added-text {
			// display: none;
			margin: -10px 0 0;

			@media (min-width: 1200px) {
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
				padding: 0 0 0 15px;
				font-weight: 300;
				font-size: 20px;
				color: #02244a;
			}

			@media (min-width: 768px) {
				margin: 0;
			}

			.time {
				display: none;
			}
		}
	}
}
