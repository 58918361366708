$theme-blue-3: #19b4fa;
$theme-blue-4: #e9f1fe;
$theme-blue-5: #4888f0;
$light-blue: #170939;

$theme-yellow: #fff04b;
$header-bg-color: #170939;
$text-blue: #00234b;
$text-black: #444;
$link-blue: #13a7dd;
$footer-blue: #112e48;

// font-family: 'Varela Round', sans-serif;
// font-family: 'Open Sans', sans-serif;
// font-family: proxima-nova, sans-serif;
$raleway: 'Raleway', sans-serif;
$opensans: 'Open Sans', sans-serif;

$text-color:		$text-black;
$link-color:		$link-blue;
$link-hover-color: $link-blue;
$headings-color: $text-blue;



