#types {
	margin: 0 auto 30px;
	max-width: 300px;
	position: relative;
	.types-menu {
		display: block;
		overflow: hidden;
		position: absolute;
		max-height:0;
		transition: max-height .2s ease-in; 
		opacity: 0;
    top: 100%;
		left: 0;
		width: 100%;
    z-index: 1000;
		padding: 8px 0;
		margin: 0;
    list-style: none;
    font-size: 15px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
		background-clip: padding-box;
		a {
			font-weight: lighter;
			color: #000;
			line-height: 1.7;
			font-size: 18px;
			padding: 3px 20px;
			display: block;
			&:hover,
			&:focus,
			&:active {
				background-color: #f5f5f5;
			}
		}
	}
	&:hover,
	&:focus,
	&:active {
		.toggle {
			background: $text-blue;
			color: #fff;
		}
		.types-menu {
			max-height: 325px;
			opacity: 1;
		}
	}
}

@media (min-width: 1200px) {
	#types {
		max-width: 100%;
		.toggle {
			display: none;
		}
		.types-menu {
			display: inline-block;
			position: relative;
			opacity: 1;
			border: none;
			box-shadow: none;
			max-height: unset;
			overflow: unset;
			@include split(3, 10px);
			a {
				border-radius: 0;
				border: 1px solid #00234b;
				color: #00234b;
				background: #fff;
				padding: 15px 0;
				width: 100%;
				font-size: 17px;
				text-align: center;
				display: block;
				margin-bottom: 10px;
			}
		}
	}
}
