// CTA - TOP

.cta-top {
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	justify-content: center;
	gap: 4px 8px;
	height: 50px;
	background-color: $header-bg-color;
	border-top: 1px solid rgba(255, 255, 255, 0.2);

	@media (min-width: 375px) {
		gap: 4px 16px;
	}

	.item1 {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
		margin-top: auto;
		line-height: 1;
		font-weight: 700;
		color: #fff;

		@media (min-width: 768px) {
			height: 100%;
			align-content: center;
			margin-top: 0;
		}
	}

	.item2 {
		grid-row: 1 / 3;
		grid-column: 2 / 3;
		padding: 0 12px;
		border-radius: 50px;
		background-color: $theme-yellow;
		color: #000;
		line-height: 36px;
		font-weight: 700;

		@media (min-width: 375px) {
			font-size: 20px;
			padding: 0 16px;
		}

		@media (min-width: 768px) {
			grid-row: 1 / 2;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: #fff;
			text-decoration: none;
		}

		i {
			font-size: 22px;
			margin-right: 4px;
			vertical-align: -2px;

			@media (min-width: 375px) {
				font-size: 24px;
				vertical-align: -3px;
			}
		}
	}

	.item3 {
		grid-row: 2 / 3;
		grid-column: 1 / 2;
		justify-self: center;
		margin-bottom: auto;
		color: #fff;

		@media (min-width: 768px) {
			grid-row: 1 / 2;
			grid-column: 3 / 4;
			margin-bottom: 0;
		}
	}
}
