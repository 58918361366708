.post {
	height: 315px;
	display: block;
	background-color: #fff;
	padding: 0;
	position: relative;
	box-shadow: 1px 1px 4px rgba(33, 33, 33, 0.1);
	margin: 0 auto 20px;
	max-width: 100%;

	.post-img {
		height: 155px;
		width: 100%;
		overflow: hidden;
		display: block;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	.post-text {
		padding: 15px;
		text-align: left;
		display: block;

		h4,
		p {
			color: #444;
		}

		h4 {
			font-size: 18px;
			font-weight: bold;
			margin-top: 0;
			margin-bottom: 10px;
		}

		p {
			font-size: 15px;
			line-height: 1.25;
		}

	}
}

.posts-block {
	@media (min-width: 992px) {
		h2 {
			text-align: left;
		}

		text-align: center;
		display: inline-block;

		article {
			width: 32%;
			display: block;
			float: left;
			margin-left: 2%;
			max-width: unset;

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

}

.posts-block.grey {
	background-color: #f8f8f8;
	display: inline-block;
	width: 100%;

	@media (min-width: 1200px) {
		padding: 15px 0;
		width: 100%;
		margin: 0;

		.container {
			max-width: 1170px;
			margin: 0 auto;
		}
	}

	.post {
		height: auto;
		margin: 0 auto 15px;
		border-radius: 7px;
		box-shadow: 1px 1px 5px rgba(128, 128, 128, 0.3);
		overflow: hidden;

		@media (max-width: 768px) {
			max-width: 90%
		}
		@media (min-width: 768px) {
			width: 48%;
			float: left;
			margin: 0 1% 15px;
		}

		@media (min-width: 1024px) {
			width: 48%;
			float: left;
			margin: 0 1% 15px;
			height: 400px;
		}

		@media (min-width: 1200px) {
			width: 23%;
			text-align: left;
			height: 300px;
		}

		img {
			width: 100%;
			height: auto;
			@media (min-width: 1200px) {
				// height: 152px;
				// width: 100%;
			}
		}

		a>span {
			display: block;
			padding: 0 10px 10px;
		}

		h4 {
			font-size: 16px;
			font-weight: 600;
			color: #000;
			margin-bottom: 10px;
		}
		.more-link {
			@media (min-width: 1200px) {
				position: absolute;
				bottom: 10px;
			}
		}
	}
}