#sidebar {
	border-top: 1px solid #e2e2e2;
	padding-top: 25px;
	max-width: 355px;
	margin: 25px auto;
	@media (min-width: 480px) {
		clear: both;
	}
	@media (min-width: 1200px) {
		width: 36%;
		float: right;
		border-top: none;
		padding-top: 0;
		margin: 0 auto 25px;
		max-width: unset;
		clear: none;
	}

	.questions {
		border: 1px solid #eee;
		border-radius: 5px;

		h4 {
			margin: 0;
			padding: 15px;
			text-align: center;
			background: #eee;
			font-size: 21px;
			font-weight: bold;
		}

		.in {
			padding: 15px;
			text-align: left;
		}

		h5 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		h6 {
			font-size: 16px;
			font-weight: bold;
			padding: 0 20px;
		}

		ul {
			padding: 0;
			margin: 0 20px 20px;
			list-style: none;
		}

		li {
			margin-bottom: 10px;
			padding-left: 30px;
		}

		i {
			margin-left: -30px;
			padding-right: 10px;
		}

		.phone {
			padding: 15px 30px;
			font-size: 20px;
			background-color: #fff04b;
			color: #00234b;
			font-weight: 700;
			display: table;
			margin: 0 auto 15px;
		}
	}
}
