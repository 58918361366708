header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 100;
	background-color: #fff;

	.line {
		height: 50px;
		padding: 0 15px;
		position: relative;

		@media (min-width: 992px) {
			max-width: 1170px;
			margin: 0 auto;
		}

		@media (min-width: 1200px) {
			padding: 0;
		}
	}

	a {
		font-weight: 600;
		font-size: 16px;
	}

	.logo {
		float: left;
		line-height: 50px;
		color: #000;
	}

	.logo i {
		margin: 0 5px 0 0;
	}

	.menu-toggle {
		float: right;
		font-size: 25px;
		cursor: pointer;
		height: 50px;
		line-height: 46px;
	}
}
